import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import BlogGrid from '../components/blog/BlogGrid';
import PageSpace from '../components/PageSpace';
import SEO from '../components/SEOFile';
import MyPortableText from '../components/typography/PortableText';
import { RegularTitle } from '../components/typography/Title';
import {SingleAuthorStyles} from '../styles/author/SingleAuthorStyles'
import AdSense from 'react-adsense';

export const authorQuery = graphql`
    query SingleAuthorQuery($id: String!){
        sanityAuthor(id: {eq: $id}){
            name
            _rawBio
            profileImage{
                asset{
                    gatsbyImageData
                }
            }
        }
        allSanityBlog(filter: {author: {id: {eq: $id}}}){
            nodes{
                id
                title
                publishedAt
                slug{
                    current
                }
                categories{
                    title
                    slug{
                        current
                    }
                }
                coverImage{
                    alt
                    asset{
                        gatsbyImageData
                    }
                }
            }
        }
    }
`

function SingleAuthor({data}) {
    const author = data.sanityAuthor;
    const blogs = data.allSanityBlog.nodes;

    return (
        <>
        <PageSpace top={80} bottom={100}>
            <SEO title={author.name}/>
            <div className='container'>
                <SingleAuthorStyles>
                    <div className='author-header'>
                        <GatsbyImage 
                            image={author.profileImage.asset.gatsbyImageData}
                            alt={author.profileImage.alt}
                            className='profileImage' 
                        />
                        <RegularTitle className="name">{author.name}</RegularTitle>
                        <div className='bio'>
                            <MyPortableText value={author._rawBio}/>
                        </div>
                    </div>
                    <hr className='hr'/>
                    <BlogGrid blogs={blogs}/>
                </SingleAuthorStyles>
            </div>
            
            
        </PageSpace>
        <AdSense.Google
        client='ca-pub-7363395996736530'
        slot='7806394673'
        style={{ display: 'block' }}
        format='auto'
        responsive='true'
        layoutKey='-gw-1+2a-9x+5c'
        /></>
    )
}

export default SingleAuthor
